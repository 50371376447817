import { React, useContext, useState } from "react";

import NoCards from "../LoyaltyCard/NoCards";
import { Swiper, SwiperSlide } from "swiper/react";
import { Link, useLocation } from "react-router-dom";
import Card from "../LoyaltyCard/Card";
import { Sdata } from "../../assest/data/Sdata";
import { useTranslation } from "react-i18next";
import Story from "../LoyaltyCard/Story";
import { motion } from "framer-motion";
import "swiper/swiper.min.css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import AddIcon from "@mui/icons-material/Add";

function LoyaltyCards({ Loyalty_Card, }) {
  const { t, i18n } = useTranslation();

  // State variables
  const [SelectedStory, setSelectedStory] = useState();
  const [StoryOpen, setStoryOpen] = useState(false);

  // Function to get the story based on ID
  function getStory(id) {
    setSelectedStory(Sdata.find((i) => i.id == id));
  }

  // Animation configuration
  const container = {
    hidden: { opacity: 1, scale: 1 },
    visible: {
      opacity: 1,
      scale: 1,
      transition: {
        duration: 0.5,
        delayChildren: 0.2,
        staggerChildren: 0.2,
      },
    },
  };

  // Breakpoints for Swiper component
  const breakpoints = {
    320: {
      slidesPerView: 5,
    },
    480: {
      slidesPerView: 7,
    },
  };

  return (
    <div>
      <div className="   w-full   flex  flex-col  px-[1.2rem]    relative   pb-20  ">
        {StoryOpen && (
          <Story stories={SelectedStory} setStoryOpen={setStoryOpen} />
        )}

        <Swiper
          breakpoints={breakpoints}
          centeredSlides={false}
          spaceBetween={20}
          grabCursor={true}
          className="mySwiper   flex-row w-full h-full     items-center justify-center   flex  mt-[1.8rem]  "
        >
          {Sdata.map((item) => (
            <SwiperSlide>
              <div
                onClick={() => {
                  getStory(item.id);
                  setStoryOpen(true);
                }}
                className={` ${
                  item.new ? "border-green-400 border-[1.3px] " : ""
                }   relative border-solid w-[4.2rem] h-[4.2rem]  rounded-[50%] border-[1px]`}
              >
                <img
                  className={` rounded-[50%]  absolute top-0 right-0 w-full h-full object-cover    p-[0.15rem]   z-30`}
                  src={item.img}
                  alt=""
                />
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
        {Loyalty_Card?.length <= 0 ? (
          <NoCards />
        ) : (
          <div>
            <div className=" pt-2"></div>
            <div className=" gap-1 w-full flex  flex-col pt-[1.3rem] ">
              <h1 className=" text-[1.1rem] font-[600]  ">
                {" "}
                {t("card.title")}{" "}
              </h1>
              <p className=" pb-[.9rem] text-sm text-[500] text-sec ">
                {t("card.p", { num: Loyalty_Card?.length })}{" "}
              </p>
              <motion.div
                variants={container}
                initial="hidden"
                animate="visible"
                className=" grid grid-cols-2  h-full gap-2"
              >
                {Loyalty_Card?.map((i) => (
                  <Card Sname={i?.store_name} id={i?.id} img={i?.front_image} />
                ))}
              </motion.div>
            </div>
          </div>
        )}
        <Link
          to={"/Cards/addcart"}
          className="   p-3 px-4  fixed   bottom-[10%] flex flex-row gap-2 items-center justify-center  right-5 bg-main  text-sm text-white rounded-lg "
        >
          <AddIcon /> {t("card.addC")}
        </Link>
      </div>
    </div>
  );
}

export default LoyaltyCards;
