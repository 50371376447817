export const handleReceiptsSearch = (Receiptes, searchTerm, setFiltredData) => {
  const results = Receiptes?.filter((receipt) => {
    // If search term is empty, return all Receiptes
    if (!searchTerm) {
      return true;
    }

    const searchTermLowerCase = searchTerm.toLowerCase();

    // Search in products' names
    const nameMatch = receipt?.name
      ?.toLowerCase()
      .startsWith(searchTermLowerCase);

    // Search in store name
    const storeMatch = receipt?.json?.shop_details?.shop_name
      ?.toLowerCase()
      .startsWith(searchTermLowerCase);

    // Search in source
    const sourceMatch = receipt?.source
      ?.toLowerCase()
      .startsWith(searchTermLowerCase);

    // Search in source email
    const sourceEmailMatch = receipt?.data?.from
      ?.toLowerCase()
      .startsWith(searchTermLowerCase);

    // Search in products' names
    const productsMatch = receipt?.json?.products.some((product) =>
      product?.name?.toLowerCase().startsWith(searchTermLowerCase)
    );

    return nameMatch || storeMatch || sourceMatch || sourceEmailMatch || productsMatch;
  });

  setFiltredData(results);
};
