import { React, useState , useEffect } from "react";
import Average from "../../assest/Average.png";
import Highest from "../../assest/Highest.png";
import Lowset from "../../assest/Lowest.png";
import { useTranslation } from "react-i18next";
import SpendingChart from "./SpendingChart";
function Spending({ filteredData, Period }) {
  const [isChartChanged, setisChartChanged] = useState(true);
  const [SelectedChart, setSelectedChart] = useState(0);
  const [totalSpent, setTotalSpent] = useState(0);
  const [Totals, setTotals] = useState({
    average: "",
    highest: "",
    lowset: "",
  });

  // Data for highest, lowest, average, and total spending
  const [Hdata, setHdata] = useState();
  const [Tdata, setTdata] = useState();
  const [Ldata, setLdata] = useState();
  const [Avrge, setAvrge] = useState();

  // Translation function for internationalization
  const { t, i18n } = useTranslation();
  const isArabic = i18n.language.startsWith("ar");

  useEffect(() => {
    // Function to get the relevant period based on the timestamp and user-specified period
    function getPeriod(timestamp, Period) {
      const date = new Date(timestamp);
      return Period === "Yesterday" || Period === "Today"
        ? date.getHours()
        : Period === "Last year" || Period === "This year"
        ? date.getMonth()
        : date.getDate();
    }

    // Initialize data structures to store totals and grouped data
    const TotalsSpent = {};
    const groupedData = {};

    // Loop through filtered data entries
    filteredData?.forEach((entry) => {
      const period = getPeriod(entry.created_at, Period);

      // Update TotalsSpent with total and createdAt information
      TotalsSpent[period] = TotalsSpent[period] || { total: 0, period: entry.created_at };
      TotalsSpent[period].total += entry?.json?.total;

      // Update groupedData with highest, lowest, and period information
      groupedData[period] = groupedData[period] || {
        highest: entry?.json?.total,
        lowest: entry?.json?.total,
        period: entry?.created_at,
      };
      if (entry.json?.total > groupedData[period].highest) {
        groupedData[period].highest = entry?.json?.total;
        groupedData[period].period = entry?.created_at;
      }
      if (entry.json?.total < groupedData[period].lowest) {
        groupedData[period].lowest = entry?.json?.total;
      }
    });

    // Extract values from TotalsSpent object
    const totalResult = Object.values(TotalsSpent)
    .filter((entry) => typeof entry.total === 'number' && !isNaN(entry.total));


    // Calculate averages and format the result for groupedData
    const result = Object.values(groupedData).map((Data) => {
      const Average = (Data.highest + Data.lowest) / 2;
      return { Average, period: Data.period, highest: Data.highest, lowest: Data.lowest };
    });

    // Set the data to the states
    setHdata({ data: result.map((i) => i.highest), time: result.map((i) => i.period) });
    setLdata({ data: result.map((i) => i.lowest), time: result.map((i) => i.period) });
    setAvrge({ data: result.map((i) => i.Average), time: result.map((i) => i.period) });
    setTdata({ data: totalResult.map((i) => i.total), time: totalResult.map((i) => i.period) });

    // Find the highest and lowest values
    const highestTotals = result.map((i) => i.highest);
    const lowTotals = result.map((i) => i.lowest);

    // Find max and min & average values
    const maxTotal = highestTotals.length > 0 ? Math.max(...highestTotals) : 0;
    const minTotal = lowTotals.length > 0 ? Math.min(...lowTotals) : 0;
   
    // Calculate the average
    const averageTotal = (maxTotal + minTotal) / 2;

    // Set the spending info (lowest, highest, average)
    setTotals({
      average: averageTotal.toFixed(0),
      lowset: minTotal.toFixed(0),
      highest: maxTotal.toFixed(0),
    });
  }, [Period]);

console.log(Tdata)

  return (
    <div className=" w-full flex flex-col pb-7  ">
      <div
        onClick={() => {
          setSelectedChart(0);
          setisChartChanged(!isChartChanged);
        }}
        className={`bg-main text-white items-start justify-center flex ${
          isArabic ? " flex-row-reverse" : " flex-row"
        }  font-[700] text-[1.4rem] py-[1.1rem] pl-[1.8rem] pr-[.8rem] w-full h-[6.4rem] rounded-[.4rem] `}
      >
        <div className=" w-full  h-full flex flex-col gap-1">
          <h1 className=" text-[1.1rem] font-[500] ">{t("Stats.TSpending")}</h1>
          <span>{totalSpent || 0}</span>
        </div>
        <span className=" ">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="23"
            height="23"
            viewBox="0 0 17 17"
            fill="none"
          >
            <path
              d="M2.66282 12.0511L0.0209351 14.6401V7.20762H2.66282M7.06596 10.4307L5.68337 9.25068L4.42408 10.4131V3.68511H7.06596M11.4691 8.96888L8.82722 11.6108V0.162598H11.4691M13.9437 8.80156L12.3497 7.20762H16.7529V11.6108L15.1765 10.0344L8.82722 16.3309L5.77144 13.6714L2.44266 16.8945H0.0209351L5.7186 11.3113L8.82722 13.9356"
              fill="white"
            />
          </svg>
        </span>
      </div>
      <SpendingChart
        setTotalSpent={setTotalSpent}
        isChartChanged={isChartChanged}
        Period={Period}
        state={SelectedChart}
        Hdata={Hdata}
        Tdata={Tdata}
        Ldata={Ldata}
        Avrge={Avrge}
      />

      <div className=" w-full flex flex-row justify-between pt-[1rem] gap-2 text-center  ">
        <div
          onClick={() => {
            setSelectedChart(1);
            setisChartChanged(!isChartChanged);
          }}
          className= {`flex flex-col justify-center w-full  items-center   pt-[.48rem] px-[.8rem] pb-[.7rem] ${ SelectedChart == 1 && " border-main" }  border-solid border-[1px] rounded-[.32rem]  gap-[.6rem] `}
        >
          <img
            src={Average}
            className=" bg-[#FFF4C7] w-[2.5rem] p-[.4rem] rounded-full h-[2.5rem]   "
            alt=""
          />
          <h1 className=" text-[.9rem] leading-5 font-medium ">
            {" "}
            {t("Stats.AT")} <br /> {t("Stats.T")}
          </h1>
          <p className=" text-[1.1rem] font-[500] ">{Totals.average || 0} </p>
        </div>

        <div
          onClick={() => {
            setSelectedChart(2);
            setisChartChanged(!isChartChanged);
          }}
          className={` flex flex-col justify-center w-full  ${ SelectedChart == 2 && " border-main" }  items-center   pt-[.48rem] px-[.8rem] pb-[.7rem]  border-solid border-[1px] rounded-[.32rem]  gap-[.6rem]`} 
        >
          <img
            src={Highest}
            className=" bg-[#FFD9D9] w-[2.5rem] p-[.4rem] rounded-full h-[2.5rem]   "
            alt=""
          />
          <h1 className=" text-[.9rem] leading-5 font-medium ">
            {t("Stats.HT")} <br /> {t("Stats.T")}
          </h1>
          <p className=" text-[1.1rem] font-[500] ">{Totals.highest || 0} </p>
        </div>
        <div
          onClick={() => {
            setSelectedChart(3);
            setisChartChanged(!isChartChanged);
          }}
          className={` flex flex-col justify-center w-full  items-center   pt-[.48rem] px-[.8rem] pb-[.7rem] ${ SelectedChart == 3 && " border-main" }   border-solid border-[1px] rounded-[.32rem]  gap-[.6rem] `}
        >
          <img
            src={Lowset}
            className=" bg-[#C7FFDA] w-[2.5rem] p-[.4rem] rounded-full h-[2.5rem]   "
            alt=""
          />
          <h1 className=" text-[.9rem] leading-5 font-medium ">
            {t("Stats.LT")} <br /> {t("Stats.T")}
          </h1>
          <p className=" text-[1.1rem] font-[500] ">{Totals.lowset || 0} </p>
        </div>
      </div>
    </div>
  );
}

export default Spending;
