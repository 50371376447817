import React, { useContext } from "react";
import banking_cards from "../../assest/banking_cards.svg";
import AddIcon from "@mui/icons-material/Add";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { CardsContext } from "../../context/Cardscontext";
function BankingCards({}) {
  const { t } = useTranslation();
  const { BankingCardsList } = useContext(CardsContext);
  console.log(BankingCardsList);
  const container = {
    hidden: { opacity: 1, scale: 1 },
    visible: {
      opacity: 1,
      scale: 1,
      transition: {
        duration: 0.5,
        delayChildren: 0.2,
        staggerChildren: 0.2,
      },
    },
  };
  console.log("BankingCardsList");

  console.log(BankingCardsList);

  return (
    <div className="  h-full  w-full   flex flex-col  px-[1.2rem]  mt-[1.8rem]  pb-20   relative    ">
      {BankingCardsList?.length <= 0 ? (
        <div className="w-full flex flex-col gap-1 h-[70vh] items-cente justify-center text-center">
          <img className="  scale-75" src={banking_cards} />
          <h1 className="pt-3 font-semibold">{t("card.NoBCard")} </h1>
          <p className=" text-gray-300"> {t("card.AddBCard")}</p>
        </div>
      ) : (
        <div className="  gap-6 flex flex-col h-full ">
          {BankingCardsList?.map((i) => (
            <div className="w-full relative  h-[12rem]">
              <Link
                to={`/Cards/BankingCardDetails/${i.id}`}
                className="rounded-md w-full h-full absolute top-0 right-0"
              >
                <img
                  className="w-full h-full rounded-2xl"
                  src={
                    typeof i?.front_image === "string"
                      ? i?.front_image
                      : i?.front_image
                      ? URL.createObjectURL(i?.front_image)
                      : "https://img.freepik.com/premium-vector/default-image-icon-vector-missing-picture-page-website-design-mobile-app-no-photo-available_87543-11093.jpg"
                  }
                  alt=""
                />
              </Link>
            </div>
          ))}
        </div>
      )}
      {/* Button to add Banking Card */}
      <Link
        to={"/Cards/addbankingcard"}
        className="   p-3 px-4  fixed   bottom-[10%] flex flex-row gap-2 items-center justify-center  right-5 bg-main  text-sm text-white rounded-lg "
      >
        <AddIcon />
        {t("card.addC")}
      </Link>
    </div>
  );
}

export default BankingCards;
