import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { useState, useEffect, useContext } from "react";
import Signin from "./page/Signin";
import Signup from "./page/Signup";
import Language from "./page/Language";
import PersonalInfo from "./page/PersonalInfo";
import Currency from "./page/Currency";
import NotFound from "./page/NotFound";
import "./App.css";
import Massege from "./compenent/Notification";
import MenuBar from "./compenent/MenuBar";
import Account from "./page/Account";
import Cards from "./page/Cards";
import Offers from "./page/Offers";
import Statistics from "./page/Statistics";
import Receipts from "./page/Receipts";
import axios from "axios";
import { useTranslation } from "react-i18next";
import Onboarding from "./compenent/Onboarding";
import LoyaltyCardDetails from "./compenent/LoyaltyCard/CardDetails";
import AddLoyaltyCard from "./compenent/LoyaltyCard/AddLoyaltyCard";
import TakeTour from "./compenent/TakeTour";
import { themecontext } from "./context/Themecontexte";
import LinkedAccount from "./page/LinkedAccount";
import InstallPage from "./compenent/InstallPage";
import BankingCardDetails from "./compenent/BankingCard/BankingCardDetails";
axios.defaults.baseURL = "https://django-cloudrun-5cc2zmgpqq-lz.a.run.app";

function App() {
  const { theme } = useContext(themecontext);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [FirstVisit, setFirstVisit] = useState(false);
  const [onloading, setonloading] = useState(true);
  const getTour = localStorage.getItem("tour");
  const [tour, setTour] = useState(getTour ? JSON.parse(getTour) : false);
  const { i18n } = useTranslation();
  var isArabic = i18n.language.startsWith("ar");

  // App Loading
  setTimeout(() => {
    setonloading(false);
  }, 10);

  // cheking this the FirstVisit
  useEffect(() => {
    const getFirstVisit = localStorage.getItem("FirstVisit");
    setFirstVisit(getFirstVisit);
    if (currentIndex >= 4) {
      setFirstVisit(true);
      localStorage.setItem("FirstVisit", FirstVisit);
    }
  }, [currentIndex]);

  useEffect(() => {
    if (currentIndex >= 4) {
      setTimeout(() => {
        setCurrentIndex(5);
      }, 2000);
    }
  }, [currentIndex]);

  return (
    <Router>
      <div
        className={`  h-[100vh]  overflow-x-hidden duration-150  ${
          isArabic ? " text-right" : " text-left"
        }    ${!theme ? " darkMode" : "lightMode "} `}
      >
        {!tour && currentIndex >= 4 && <TakeTour setTour={setTour} />}

        {/* notifcations */}
        <Massege />

        {/* Onboarding page */}
        {!FirstVisit && currentIndex <= 4 && (
          <Onboarding
            currentIndex={currentIndex}
            setCurrentIndex={setCurrentIndex}
          />
        )}
        
         {/* Instalation Pgae */}
        {/* <InstallPage /> */}
       
       {/* Loading white page */}
        {onloading && (
          <div className="  font-customs fixed bg-white w-full h-full top-0 right-0 z-[70] "></div>
        )}
        <Routes>
          <Route path="/Account" element={<Account />} />
          <Route path="/Signup" element={<Signup />} />
          <Route path="/Signin" element={<Signin />} />
          <Route path="/Signin/:subpages" element={<Signin />} />
          <Route path="/PersonalInfo" element={<PersonalInfo />} />
          <Route path="/Language" element={<Language />} />
          <Route path="/Currency" element={<Currency />} />
          <Route path="/Cards" element={<Cards />} />
          <Route path="/Cards/:subpages" element={<Cards />} />
          <Route path="/Cards/LoyaltyCardDetails" element={<LoyaltyCardDetails />} />
          <Route path="/Cards/LoyaltyCardDetails/:id" element={<LoyaltyCardDetails />} />
          <Route path="/Cards/BankingCardDetails/:id" element={<BankingCardDetails />} />
          <Route path="/Cards/BankingCardDetails" element={<BankingCardDetails />} />
          <Route
            path="/Cards/addcartmanully/:id"
            element={<AddLoyaltyCard />}
          />
          <Route path="/Offers" element={<Offers />} />
          <Route path="/linkedaccounts" element={<LinkedAccount />} />
          <Route path="/Statistics" element={<Statistics />} />
          <Route path="/Statistics/:subpages" element={<Statistics />} />
          <Route index element={<Receipts />} />
          {/* Page Not Found Route */}
          <Route path="*" element={<NotFound />} />
        </Routes>
        {/* Menu Bar */}
        <MenuBar />
      </div>
    </Router>
  );
}

export default App;
