import { React, useState, useContext, useEffect, useRef } from "react";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Link, useLocation } from "react-router-dom";
import Eimpact from "../compenent/Sratistics/Eimpact";
import Billing from "../compenent/Sratistics/Billing";
import Merchant from "../compenent/Sratistics/Merchant";
import ReceiptType from "../compenent/Sratistics/ReceiptType";
import Spending from "../compenent/Sratistics/Spending";
import Trends from "../compenent/Sratistics/Trends";
import { useTranslation } from "react-i18next";
import TopBar from "../compenent/TopBar";
import CreditO from "../compenent/Sratistics/CreditO";
import { themecontext } from "../context/Themecontexte";
import { Receiptscontext } from "../context/Receiptscontext";
import { useClickOutside } from "../hooks/useClickOutside";

function Statistics() {
  const { Receiptes } = useContext(Receiptscontext);
  const { theme } = useContext(themecontext);
  const [StatsLink, setStatsLink] = useState(false);
  const [PerOpen, setPerOpen] = useState(false);
  const [Period, setPeriod] = useState("Today");
  const { pathname } = useLocation();
  const { t, i18n } = useTranslation();
  const PeriodRef = useRef();
  const StatsRef = useRef();

  var isArabic = i18n.language.startsWith("ar");
  var isFr = i18n.language.startsWith("fr");

  const currentDate = new Date();
  let startDate = new Date();

  if (Period === "Today") {
    startDate.setHours(0, 0, 0, 0);
} else if (Period === "Yesterday") {
    startDate.setDate(currentDate.getDate() - 1);
    startDate.setHours(0, 0, 0, 0);
} else if (Period === "This week") {
    startDate.setDate(currentDate.getDate() - currentDate.getDay());
    startDate.setHours(0, 0, 0, 0);
} else if (Period === "Last week") {
  startDate.setDate(currentDate.getDate() - 7);
  startDate.setHours(0, 0, 0, 0);

} else if (Period === "This month") {
    startDate.setDate(currentDate.getDate() - 29); // Last 30 days
    startDate.setHours(0, 0, 0, 0);
} else if (Period === "Last month") {
    startDate.setDate(currentDate.getDate() - 59); // Days between 30 and 60 days ago
    startDate.setHours(0, 0, 0, 0);
} else if (Period === "This year") {
    startDate.setMonth(0, 1);
    startDate.setHours(0, 0, 0, 0);
}else if (Period === "Last year") {
  startDate.setFullYear(currentDate.getFullYear() - 1);
  startDate.setMonth(currentDate.getMonth() - 11); // Subtracting 11 to get the start of the previous 12 months
  startDate.setDate(1); // Start from the 1st day of the month
  startDate.setHours(0, 0, 0, 0);
}

  // Filtring the Receipts by the Selected Period 
  const filteredData = Receiptes?.filter((item) => {
    const itemDate = new Date(item.created_at);
    return itemDate >= startDate;
  }).reverse();

  // states Link 
  const StatsArray = [
    { text: t("Stats.SpendingO"), link: "/Statistics" },
    { text: t("Stats.BStatus"), link: "/Statistics/Billing" },
    { text: t("Stats.Analysis"), link: "/Statistics/Merchant" },
    { text: t("Stats.STrends"), link: "/Statistics/Trends" },
    { text: t("Stats.RType"), link: "/Statistics/ReceiptType" },
    { text: t("Stats.EImpact"), link: "/Statistics/Impact" },
    { text: t("Stats.TCrédit"), link: "/Statistics/CreditO" },
  ];
// Periods 
  const PeriodArray = [
    { text: t("filter.Today"), value: "Today" },
    { text: t("filter.Yesterday"), value: "Yesterday" },
    { text: t("filter.Thisweek"), value: "This week" },
    { text: t("filter.Lastweek"), value: "Last week" },
    { text: t("filter.Thismonth"), value: "This month" },
    { text: t("filter.Lastmonth"), value: "Last month" },
    { text: t("filter.Thisyear"), value: "This year" },
    { text: t("filter.Lastyear"), value: "Last year" },
  ];


// closing the tab when Clicking outSide the tab -----
  const closeStatsLink = () => {
    setStatsLink(false);
  };
  const closePeriod = () => {
    setPerOpen(false);
  };
  useClickOutside(StatsRef, closeStatsLink);
  useClickOutside(PeriodRef, closePeriod);
//  ------------------------------------------

  return (
    <div className="   px-[1.2rem] pt-[1.4rem] pb-20  overflow-hidden   ">
      <TopBar text={"My Stats"} />
      <div
        className={` flex  ${
          isArabic ? "  flex-row-reverse" : " flex-row"
        } w-full    justify-around pt-[2rem] pb-[1.6rem] `}
      >
        <span
          ref={StatsRef}
          onClick={() => setStatsLink(!StatsLink)}
          className={`relative flex ${
            isArabic ? "  flex-row-reverse" : " flex-row"
          } w-[66%] justify-between   items-center   px-1 `}
        >
          <h1 className=" text-[1.1rem] font-[500] ">
            {StatsArray.find((i) => i.link == pathname).text}
          </h1>
          <span className=" text-main">
            {" "}
            <KeyboardArrowDownIcon
              className={` ${
                StatsLink ? " -rotate-180" : " rotate-0"
              } duration-150 `}
            />{" "}
          </span>

          <div
            className={` duration-150    z-40 flex flex-col  top-14 right-0 rounded w-full absolute   ${
              StatsLink ? "h-[17.8rem]" : " h-0"
            }   ${
              !theme ? " bg-[#1C1C1C] " : "bg-[#F9F9FF]"
            }  shadow overflow-hidden `}
          >
            {StatsArray.map((i) => (
              <div id={i.index} className=" flex flex-col">
                <Link
                  to={i.link}
                  className={` ${
                    i.link == pathname ? " text-main" : ""
                  }   p-2 `}
                >
                  {" "}
                  {i.text}{" "}
                </Link>
                <span
                  className={` w-full h-[1px]  ${
                    !theme ? " bg-[#1C1C1C] bg-[#f2f2f22f]  " : " bg-[#e9e7e7] "
                  }  `}
                ></span>
              </div>
            ))}
          </div>
        </span>

        <span
          onClick={() => setPerOpen(!PerOpen)}
          ref={PeriodRef}
          className={`relative flex ${
            isArabic ? "  flex-row-reverse" : " flex-row"
          }     pl-2 w-[42%] h-[3.2rem]     justify-around   border-solid border-[1px]  rounded-full   items-center  `}
        >
          <h1 className=" text-[.9rem] font-[500] ">
            {" "}
            {PeriodArray.find((i) => i.value == Period).text}{" "}
          </h1>
          <span className=" text-main">
            {" "}
            <KeyboardArrowDownIcon
              className={` ${
                PerOpen ? " -rotate-180" : " rotate-0"
              } duration-150 `}
            />{" "}
          </span>
          <div
            className={` duration-150    z-40 flex flex-col  top-14 right-0 rounded w-full absolute   ${
              PerOpen ? ` ${!isFr ? " h-[19rem]" : "h-[20.4rem]"}   ` : " h-0"
            }   ${
              !theme ? " bg-[#1C1C1C] " : "bg-[#F9F9FF]"
            } shadow overflow-hidden `}
          >
            {PeriodArray.map((i) => (
              <div className=" flex text-[.9rem] flex-col">
                <Link
                  onClick={() => setPeriod(i.value)}
                  className={`  ${Period == i.value ? " text-main" : ""}  p-2 `}
                >
                  {" "}
                  {i.text}
                </Link>
                <span
                  className={` w-full h-[1px]  ${
                    !theme ? " bg-[#1C1C1C] bg-[#f2f2f22f]  " : " bg-[#e9e7e7] "
                  }  `}
                ></span>
              </div>
            ))}
          </div>
        </span>
      </div>

      {pathname == "/Statistics" && (
        <Spending Period={Period} filteredData={filteredData} />
      )}
      {pathname == "/Statistics/Billing" && (
        <Billing Period={Period} filteredData={filteredData} />
      )}
      {pathname == "/Statistics/Trends" && (
        <Trends Period={Period} filteredData={filteredData} />
      )}
      {pathname == "/Statistics/ReceiptType" && (
        <ReceiptType Period={Period} filteredData={filteredData} />
      )}
      {pathname == "/Statistics/Merchant" && (
        <Merchant Period={Period} filteredData={filteredData} />
      )}
      {pathname == "/Statistics/Impact" && (
        <Eimpact Period={Period} PeriodArray={PeriodArray} filteredData={filteredData} />
      )}
      {pathname == "/Statistics/CreditO" && (
        <CreditO Period={Period}   filteredData={filteredData} />
      )}
    </div>
  );
}

export default Statistics;
