import { React, useRef, useEffect, useState } from "react";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import "@splidejs/splide/dist/css/splide.min.css"; // Default theme
import "../../App.css";
import { useTranslation } from "react-i18next";

function Merchant({ Period, filteredData }) {
  const [Merch, setMerch] = useState();
  const [FilterMerch, setFilterMerch] = useState();
  const { t, i18n } = useTranslation();
  var isArabic = i18n.language.startsWith("ar");
  const [searchTerm, setSearchTerm] = useState("");

  // Search functionality -----------
  useEffect(() => {
    setFilterMerch(Merch);
  }, [Merch]);

  const handleSearch = () => {
    const merchh = Merch?.filter((item) =>
      item.Name.toLowerCase().startsWith(searchTerm.toLowerCase())
    );
    setFilterMerch(merchh);
  };

  useEffect(() => {
    handleSearch();
  }, [searchTerm]);

  const handleInputChange = (e) => {
    setSearchTerm(e.target.value);
    handleSearch();
  };
// ----------------------------


  useEffect(() => {
    function calculateShopStats(data) {
      const shopStats = {};

      data?.forEach((entry) => {
        if (!entry?.json) {
          return;
        }
        const shopId = entry.shop;
        const shopName =
          entry?.json?.shop_details?.shop_name || `Shop ${shopId}`;

        if (shopStats[shopId] === undefined) {
          shopStats[shopId] = {
            name: shopName,
            visitCount: 1,
            totalSpending: entry?.json?.total,
            purchaseCount: entry?.json?.products.length || 0,
            };
        } else {
          shopStats[shopId].visitCount++;
          shopStats[shopId].totalSpending += entry?.json?.total;
          shopStats[shopId].purchaseCount += entry?.json?.products.length || 0;
        }
      });

      // Calculate average spending for each shop
      for (const shopId in shopStats) {
        const shop = shopStats[shopId];
        shop.averageSpending = shop.totalSpending / shop.visitCount;
      }

      return Object.values(shopStats);
    }

    const shopStatistics = calculateShopStats(filteredData);

    const transformedData = shopStatistics.map((shop) => ({
      Name: shop.name,
      VisitCount: shop.visitCount,
    // cheking if the value  is not NaN
      AverageSpending: isNaN(shop.averageSpending) ? 0 : shop.averageSpending,
      TotalSpending: isNaN(shop.totalSpending) ? 0 : shop.totalSpending,
      PurchaseCount: shop.purchaseCount,
    }));

    setMerch(transformedData);
  }, [Period]);

  return (
    <div className=" text-left flex w-full flex-col h-full">
      <span className=" relative w-full ">
        <p
          className={` text-[#9C9C9C]  flex flex-row gap-[.35rem] items-center  absolute  top-[1.5rem]   ${
            isArabic ? " right-3" : " left-3"
          } `}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="13"
            height="13"
            viewBox="0 0 13 13"
            fill="none"
          >
            <path
              d="M9.64153 8.21094H9.06072L8.85486 8.01243C9.31433 7.47865 9.65013 6.84991 9.83823 6.1712C10.0263 5.49249 10.0621 4.78059 9.94296 4.08645C9.59742 2.04259 7.89175 0.410438 5.83318 0.160469C5.10946 0.0689108 4.37438 0.144126 3.6842 0.38036C2.99402 0.616593 2.36703 1.00758 1.8512 1.52341C1.33537 2.03924 0.944382 2.66623 0.708149 3.35641C0.471916 4.04659 0.3967 4.78167 0.488258 5.50539C0.738227 7.56396 2.37038 9.26963 4.41424 9.61517C5.10838 9.73431 5.82028 9.69855 6.49899 9.51044C7.1777 9.32234 7.80644 8.98654 8.34022 8.52707L8.53873 8.73293V9.31374L11.6633 12.4384C11.9648 12.7398 12.4574 12.7398 12.7588 12.4384C13.0602 12.1369 13.0602 11.6443 12.7588 11.3429L9.64153 8.21094ZM5.23031 8.21094C3.39966 8.21094 1.9219 6.73318 1.9219 4.90252C1.9219 3.07187 3.39966 1.59411 5.23031 1.59411C7.06097 1.59411 8.53873 3.07187 8.53873 4.90252C8.53873 6.73318 7.06097 8.21094 5.23031 8.21094Z"
              fill="#C1C1C1"
            />
          </svg>{" "}
        </p>
        <input
          value={searchTerm}
          onChange={handleInputChange}
          type="text"
          className={` text-[.75rem] px-8  ${
            isArabic ? "  text-right " : "  text-left"
          }    bg-gray-500 bg-opacity-5  rounded-[.3rem] w-full h-[3.8rem] `}
          placeholder={t("profile.searchPlaceh") + ` ` + t("Stats.Merchant")}
        />
      </span>

      <div className=" w-full  h-full pt-[1.5rem]  ">
        <Splide
          style={{ width: "100%", height: "100%" }}
          className="splide-container w-full h-[60vh] "
          options={{
            type: "slid", 
            pagination: true,
            arrows: false,  
          }}
          aria-label=" "
        >
          <SplideSlide className=" h-full w-full">
            <div className="custom-scroll-container  w-full flex flex-col    h-[56vh] ">
              <div
                className={`font-[500] pb-4 pr-[1.3rem]  flex  ${
                  isArabic ? " flex-row-reverse " : " flex-row"
                } justify-between items-center  `}
              >
                <h1>{t("Stats.Merchant")}</h1>
                <h1>{t("Stats.Visit")}</h1>
                <h1>{t("Stats.Purchase")}</h1>
              </div>

              <div
                className={`content   flex flex-col  h-full    ${
                  isArabic ? "  pr-4 " : "  pr-8"
                }  pb-5    overflow-auto  pt-[.3rem] gap-5`}
              >
                {FilterMerch?.map((i) => (
                  <div
                    className={`flex  ${
                      isArabic ? " flex-row-reverse " : " flex-row"
                    } justify-between items-center`}
                  >
                    <h1 className={` w-[120%] ${isArabic ?' text-right' :' text-left'} `} >
                      {i.Name?.length >= 8
                        ? i.Name?.substring(0, 8) + ".."
                        : i.Name}
                    </h1>
                    <h1
                      className={` w-full ${
                        isArabic ? "pl-10  " : ""
                      }    text-center  text-main `}
                    >
                      {i.VisitCount}
                    </h1> 
                    <h1 className={`w-full  ${
                        !isArabic ? "  text-right " : "   text-left"
                      }  text-[#06C671]`}>
                      {i.PurchaseCount}{" "}
                    </h1>
                  </div>
                ))}
              </div>
            </div>
          </SplideSlide>
          <SplideSlide className=" h-full w-full">
            <div className=" custom-scroll-container  w-full flex flex-col    h-[56vh] ">
              <div
                className={`text-center  text-[.9rem] font-[500] pb-3  w-full  flex  ${
                  isArabic ? " flex-row-reverse" : " flex-row"
                } justify-between items-start`}
              >
                <h1
                  className={` w-[90%] ${
                    !isArabic ? "text-start   " : "  text-end "
                  } `}
                >
                  {" "}
                  {t("Stats.Merchant")}
                </h1>
                <h1 className="   w-full   ">
                  {t("Stats.ASvisit")} <br /> {t("Stats.visit")}
                </h1>
                <h1
                  className={`${
                    isArabic ? "text-start   " : "  text-end "
                  }  w-full `}
                >
                  {t("Stats.TASpent")} <br /> {t("Stats.sepnt")}
                </h1>
              </div>

              <div className=" content   flex flex-col  h-full   pb-5     overflow-auto     gap-5 ">
                {FilterMerch?.map((i) => (
                  <div
                    className={`flex  ${
                      isArabic ? " flex-row-reverse " : " flex-row"
                    } justify-between items-center`}
                  >
                    <h1
                      className={`   ${
                        isArabic ? "  text-right " : "   text-left"
                      }  w-full`}
                    >
                      {" "}
                      {i.Name?.length >= 8
                        ? i.Name?.substring(0, 8) + ".."
                        : i.Name}
                    </h1>
                    <h1 className={`w-full text-center  text-[#EB9B00]`}>
                      {i.AverageSpending.toFixed(1) || 0}
                    </h1>
                    <h1
                      className={`w-full  ${
                        !isArabic ? "  text-right " : "   text-left"
                      }  text-[#06C671]`}
                    >
                      {i.TotalSpending}{" "}
                    </h1>
                  </div>
                ))}
              </div>
            </div>
          </SplideSlide>
        </Splide>
      </div>
    </div>
  );
}

export default Merchant;
